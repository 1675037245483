@import "~bulma/bulma.sass";

.hero {
  &.is-dark {
    background: linear-gradient(-45deg, #121212, #2c2c2c, #1f1f1f, #3a3a3a);
    background-size: 400% 400%;
    animation: gradient-animation 8s ease infinite;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.erp-project {
  .column {
    padding: 3em 1.5em !important;
  }
}

.hero {
  background: rgb(34, 34, 34);
  background: linear-gradient(
    170deg,
    rgb(0, 0, 0) 0%,
    rgb(31, 31, 31) 40%,
    rgb(52, 52, 52) 100%
  );
}

body {
  font-size: 20px;
}

@include mobile {
  #header {
    padding-bottom: 0px;
  }

  #about-me {
    padding-top: 0px;
  }
}
